<template>
    <div>
        <div class="big">
            <div class="header">
                <p>请选择查询条件:</p>
            </div>
            <div class="title">
                <span>起始时间:&nbsp;&nbsp;</span>
                <el-date-picker v-model="startTime" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日"
                    value-format="timestamp">
                </el-date-picker>
                <span>终止时间:&nbsp;&nbsp;</span>
                <el-date-picker v-model="endTime" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日"
                    value-format="timestamp">
                </el-date-picker>
                <span>操作类型:&nbsp;&nbsp;</span>
                <el-select v-model="opctionsValue">
                    <el-option v-for="(item, index) in optionsType" :key="index" :label="item.value"
                        :value="item.value"></el-option>
                </el-select>
                <span>操作人员:&nbsp;&nbsp;</span>
                <el-select v-model="operatorValue">
                    <el-option v-for="(item, index) in operator" :key="index" :label="item.value"
                        :value="item.value"></el-option>
                </el-select>
                <el-button @click="searchOrder" style="margin-left: 20px;">查询</el-button>
            </div>
            <div v-if="orderList.length > 0" class="order" style="margin-top: 20px; width: 100%;">
                <el-container style="height: 50px; border: 1px solid #eee">
                    <el-aside width="290px" height="100px">
                        <el-menu>
                            <el-menu-item-group>
                                <div>所有订单:({{ orderList.length }})</div>
                                <el-menu-item v-for="(item,index) in orderList" :key="index" @click="searchOrderInfo(index,item.number,item.createtime,item.address)">订单编号:{{ item.number }}</el-menu-item>
                            </el-menu-item-group>
                        </el-menu>
                    </el-aside>
                    <div>
                        <div class="order_basic_top">订单基本信息</div>
                        <ul v-for="(item,index) in orderInfo" style="margin-bottom: 50px;" class="basic_content">
                            <li>订单编号:{{ orderInfoNum }}</li>
                            <li>订单时间:{{ orderInfoTime }}</li>
                            <li>商品ID:{{ item.order_id }}</li>
                            <li>商品类型:{{ item.type_title }}</li>
                            <li style="width: 282px;  white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
                                商品名称:{{ item.title }}</li>
                            <li>电话:{{ item.mobile }}</li>
                            <li style="width: 282px;  white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
                                地址:{{ orderAddress }}</li>
                        </ul>
                    </div>
                </el-container>
            </div>
            <div v-else style="margin-top: 150px;">
                <el-empty description="暂无内容"></el-empty>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
export default {
    name: 'searchInfo',
    components: {

    },
    data() {
        return {
            startTime: '',
            endTime: '',
            optionsType: [{ value: '全部' }, { value: '收件' }, { value: '拆包' }, { value: '拒收' }, { value: '上挂' }],
            operator: [{ value: '全部' }, { value: '员工1' }, { value: '员工2' }, { value: '员工3' }],
            opctionsValue: '',
            operatorValue: '',
            isShow:false,
            orderList:[],
            orderInfo:{},
            orderInfoNum:'',
            orderInfoTime:'',
            orderAddress:''
        }
    },
    computed: {

    },
    methods: {
        searchOrder() {
            let status = 0;
            if(this.opctionsValue == '上挂'){
                status = 4;
            }else if(this.opctionsValue == '收件'){
                status = 1;
            }else if(this.opctionsValue == '拆包'){
                status = 2;
            }else if(this.opctionsValue == '拒收'){
                status = 3;
            }else{
                status = 0;
            }
            let obj = {
                starttime:this.startTime,
                endtime:this.endTime,
                status:status
            }
            this.$http.post('/index/orderListFilter',obj,(res) => {
                if(res.code == 1){
                    this.orderList = res.data.list;
                    this.orderInfo = this.orderList[0].order_goods;
                    this.orderInfoTime = new Date(this.orderList[0].createtime).toISOString().split('T')[0];
                    this.orderInfoNum = this.orderList[0].number;
                    this.orderAddress = this.orderList[0].address;
                }
            })
        },
        searchOrderInfo(index,Num,time,address) {
            this.orderInfoTime = new Date(time*1000).toISOString().split('T')[0];
            this.orderInfoNum = Num;
            this.orderInfo = this.orderList[index].order_goods;
            this.orderAddress = address;
        },
    },
}
</script>

<style lang="scss" scoped>
.big {
    width: 100%;
    height: 900px;
    background: #fff;

    .header {
        width: 100%;

        p {
            margin-left: -80px;
        }
    }

    .order {
        .orderInfo {
            width: 500px;
            height: 900px;
            background-color: white;
        }

        .basic_content {
            display: flex;
            flex-flow: row wrap;
            border: 1px solid #ddd;
            border-bottom: none;
            font-size: 0.1875rem;
            color: #191919;
        }

        .basic_content li {
            width: 25%;
            height: .5rem;
            line-height: 0.5rem;
            padding: 0 .125rem;
            border-bottom: 1px solid #ddd;
            border-right: 1px solid #ddd;
        }
    }
}
</style>