<template>
    <div>
        <div class="big">
            <div class="order">
                <div class="search">
                    <el-input class="elInput" v-model="inputValue" placeholder="请输入内容"></el-input>
                    <el-button @click="searchorder" type="primary" size="mini">查询</el-button>
                </div>
                <div class="orderItem">
                    <ul class="orderist">
                        <li>订单编号:JKJX123456</li>
                        <li>商品名称:羽绒服</li>
                        <li>商品类别:当季特惠</li>
                        <li>商品ID:1476</li>
                    </ul>
                    <el-button @click="getRejection" type="danger" class="dangerBtn" size="mini">拒收件</el-button>
                </div>
                <div class="orderBtn">
                    <el-button @click="addGoodsFlag = true" style="margin-left: 110px"
                        type="warning">增加商品</el-button><br>
                    <el-button @click="removeGoodsFlag = true" style="margin-top: 20px; margin-left: 110px"
                        type="danger">删除商品</el-button><br>
                    <el-button style="margin-top: 20px; margin-left: 110px" type="primary">提交订单</el-button>
                </div>
                <!-- 添加商品弹框 -->
                <el-dialog title="商品信息" :visible.sync="addGoodsFlag">
                    <el-form :model="form">
                        <el-form-item label="商品名称" :label-width="formLabelWidth">
                            <el-input v-model="form.name" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item label="商品类别" :label-width="formLabelWidth">
                            <el-select v-model="form.region" placeholder="请选择商品类型">
                                <el-option label="当季特惠" value="当季特惠"></el-option>
                                <el-option label="鞋靴" value="鞋靴"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                    <div slot="footer" class="dialog-footer">
                        <el-button @click="addGoodsFlag = false">取 消</el-button>
                        <el-button type="primary" @click="addGoods">确 定</el-button>
                    </div>
                </el-dialog>
                <!-- 拒收商品弹框 -->
                <el-dialog title="商品信息" :visible.sync="rejectionGoodsFlag">
                    <div class="flexPullDown">
                        <div class="ioo">拒收类型<div style="font-weight: 600;">：</div>
                        </div>
                        <select v-model="selectedItem" class="pullDown">
                            <!-- <option value="">请选择拒收类型</option> -->
                            <option v-for="(item, index) in rejectionType" :key="index" :value="item.id">{{ item.name }}
                            </option>
                        </select>

                    </div>
                    <el-input type="textarea" :rows="4" placeholder="请输入拒收原因" v-model="textarea">
                    </el-input>
                    <span slot="footer" class="dialog-footer">
                        <el-button type="primary" @click="subRejection">保 存</el-button>
                    </span>
                </el-dialog>
                <!-- 删除商品弹框 -->
                <el-dialog title="输入要删除的商品ID" :visible.sync="removeGoodsFlag">
                    <el-form>
                        <el-form-item label="商品ID:" :label-width="formLabelWidth">
                            <el-input v-model="form.goodsId" autocomplete="off" placeholder="请输入商品ID"></el-input>
                        </el-form-item>
                    </el-form>
                    <div slot="footer" class="dialog-footer">
                        <el-button @click="removeGoodsFlag = false">取 消</el-button>
                        <el-button type="primary" @click="removeGoods">确 定</el-button>
                    </div>
                </el-dialog>
            </div>
            <div class="orderInfo">
                <p>订单基本信息</p>
                <ul class="list">
                    <li>订单编号:</li>
                    <li>RFID:</li>
                    <li>商品ID:</li>
                    <li>商品类别:</li>
                    <li>商品名称:</li>
                    <li>水洗唛码:</li>
                    <li>姓名:</li>
                    <li>电话:</li>
                    <li>地区:</li>
                    <li>时间:</li>
                    <li>衣物注意事项:</li>
                    <li>备注:</li>
                </ul>
                <!-- 修改商品弹框 -->
                <div class="revise">
                    <el-button type="success" @click="fixGoodsFlag = true">修改此商品</el-button>
                    <el-dialog title="商品信息" :visible.sync="fixGoodsFlag">
                        <el-form :model="form">
                            <el-form-item label="商品名称" :label-width="formLabelWidth">
                                <el-input v-model="form.name" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item label="商品类别" :label-width="formLabelWidth">
                                <el-select v-model="form.region" placeholder="请选择商品类型">
                                    <el-option label="当季特惠" value="当季特惠"></el-option>
                                    <el-option label="鞋靴" value="鞋靴"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                        <div slot="footer" class="dialog-footer">
                            <el-button @click="fixGoodsFlag = false">取 消</el-button>
                            <el-button type="primary" @click="fixGoods">确 定</el-button>
                        </div>
                    </el-dialog>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    components: {

    },
    data() {
        return {
            inputValue: '',
            addGoodsFlag: false,
            removeGoodsFlag: false,
            fixGoodsFlag: false,
            rejectionGoodsFlag: false,
            form: {
                name: '',
                region: '',
                goodsId: ''
            },
            formLabelWidth: '120px',
            rejectionType: [],
            selectedItem: '',
            selectedItemText: '',
            textarea: ''
        }
    },
    computed: {

    },
    methods: {
        // 修改商品回调
        fixGoods() {
            console.log(this.form.name, this.form.region, '修改商品所需要的参数');
            this.fixGoodsFlag = false;
            this.form.name = '';
            this.form.region = ''
        },
        // 删除商品回调
        removeGoods() {
            console.log('删除商品回调执行');
        },
        // 增加/修改商品回调
        addGoods() {
            console.log(this.form.name, this.form.region, '增加商品所需要的参数');
            this.addGoodsFlag = false;
            this.form.name = '';
            this.form.region = ''
        },
        // 保存拒收回调
        subRejection() {
            if (this.textarea == '') {
                this.$message({
                    type: 'error',
                    message: '请输入拒收原因'
                })
                return;
            }
            if (this.selectedItem == '') {
                this.$message({
                    type: 'error',
                    message: '请选择拒收类型'
                })
                return;
            }
            console.log(this.selectedItem, this.textarea);
        },
        // 查询订单回调
        searchorder() {
            console.log('查询执行', this.inputValue);
            this.inputValue = '';
        },
        // 获取拒收类别
        getRejection() {
            this.rejectionGoodsFlag = true;
            this.$http.post('receiving/getstatus', {}, (res) => {
                if (res.code == 1) {
                    this.rejectionType = res.data;
                    // console.log(this.rejectionType);
                }
            })
        },

    },
}
</script>

<style lang="scss" scoped>
.big {
    width: 100%;
    height: 900px;
    background-color: white;
    overflow: hidden;
    display: flex;

    .order {
        width: 380px;
        height: 100%;
        border-right: 1px solid #ccc;

        .search {
            .elInput {
                margin: 10px;
            }
        }

        .orderItem {
            padding-left: 10px;
            padding-right: 10px;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid gainsboro;

            ul {
                margin-top: 10px;
                font-size: 15px;
                margin-bottom: 10px;
            }

            .dangerBtn {
                margin-top: 15px;
                height: 30px;
            }

        }

        .orderBtn {
            margin-top: 20px;
        }
    }

    .orderInfo {
        margin-left: 10px;

        p {
            height: .375rem;
            background: #0099ff;
            color: #fff;
            width: 100%;
            line-height: .375rem;
            padding: 0 .25rem;
            font-size: 0.2rem;
            font-weight: 550;
        }

        .list {
            box-sizing: border-box;
            display: flex;
            flex-wrap: wrap;
            font-size: 16px;
            color: rgb(85, 83, 83);

            li {
                width: 415px;
                border: 1px solid #ccc;
                box-sizing: border-box;
                padding: 3px;
            }
        }

        .revise {
            margin: 20px auto;
        }
    }
}
</style>