<template>
    <div class="sendAgain" style="position: relative;">
        <el-input ref="input" style="position: absolute;" v-model="goodsId" @change="sendGoodsId"
            placeholder="请输扫描或输入水洗唛码"></el-input>
        <div class="order">
            <div class="order_content" style="padding: 0 0.125rem;overflow: hidden; margin-top: 40px;">
                <order indexFlag="3" :showBtn="'1'" :orderList="orderList" allStatus="receiving"  @orderStatus="handleChildEvent" @orderCategory="handleChildEvent1"></order>
            </div>
        </div>
        <div class="order_intro">
            <div class="order_i_top">
                <p>订单详情</p>
            </div>
            <div class="order_i_content">
                <div class="order_i_c_l">
                    <orderInfo :authid="authid" indexFlag="3" :rejectionFlag="false" :giveStatus="0" :orderid="orderList[Listindex]" orderCategory="receiving"></orderInfo>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import order from './order.vue'
import orderInfo from './orderInfo.vue'
export default {
    name: '',
    components: {
        order,
        orderInfo
    },
    props: {
        indexFlag: {
            type: String
        }
    },
    data() {
        return {
            goodsId: '',
            Recivelist:[],
            orderList:[],
            authid:'1',
            status:'',
            ogid:'',
            category:'receiving',
            Listindex:0            
        }
    },
    computed: {

    },
    watch:{
        indexFlag(newValue,oldValue){
            console.log(newValue,oldValue);
            this.$refs.input.focus();
        },
        immediate: true,
        deep: true
    },
    mounted() {

    },
    methods: {
        async sendGoodsId() {
            let uid = JSON.parse(localStorage.getItem('userinfo')).id;
            let obj = {
                ogid:this.goodsId,
                id:uid
            }
            await this.$http.post(`/receiving/waterWashedLabelOrderInfo`, obj, (res) => {
                if(res.code == '1'){
                    // console.log('请求发送成功111111111',res.data);
                    this.orderList = res.data;
                }else{
                    this.$message({
                        message:'无效',
                        type:'warning'
                    })
                }
			});
            this.goodsId = '';
        },
        handleChildEvent(e) {
            this.status = e
            this.Listindex = e
        },
        handleChildEvent1(e) {
            this.category = e
        }
    },
}
</script>

<style lang="scss" scoped>
.sendAgain {
    display: flex;
    height: 100%;
}

.box_code {
    display: flex;
    align-items: center;
    padding: 0 0.125rem;
    margin: 0.125rem 0;
}

.box_code label {
    width: 1.2rem;
    font-size: 0.15rem;
}

.box_code .el-input__inner {
    height: 0.4rem;
    flex: 1;
}

.order {
    background: #fff;
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.order_top {
    display: flex;
    justify-content: space-between;
    align-content: center;
    font-size: .175rem;
    padding: .125rem;
    border-bottom: 1px solid #eee;
}

.order_content {
    padding: 0 0.125rem;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
}

.order_status {
    display: flex;
    align-items: center;
    font-size: .185rem;
    font-weight: 500;
    color: #333;
}

.order_status li {
    margin-right: .1875rem;
    cursor: pointer;
}

.order_status li.active {
    color: rgba(0, 153, 255, 1);
}

.order_intro {
    height: 100%;
    background: #fff;
    margin-left: .125rem;
    flex: 1;
}

.order_i_top {
    padding: .155rem .155rem 0 .225rem;
    border-bottom: 1px solid #eee;
}

.order_i_top p {
    height: .375rem;
    font-size: .1875rem;
    color: rgba(0, 153, 255, 1);
    border-bottom: 2px solid rgba(0, 153, 255, 1);
    display: inline-block;
}

.order_i_content {
    display: flex;
}

.order_i_c_l {
    padding: .1875rem;
    flex: 1;
}

.all_fenlei {
    height: .5rem;
    background: #d4e9ff;
    text-align: center;
    line-height: .5rem;
    font-size: 0.2rem;
    color: #48a3ff;
}

.bagging {
    margin: .25rem 0;
}
</style>