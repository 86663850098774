<template>
	<div class="container">
		<!-- <div class="input_content">
                <input @change="onInputOrderId()" @blur="getFocus()" v-model="orderId" ref="inputOrderId">
            </div> -->
		<el-container class="container">
			<el-header>
				<Heads></Heads>
			</el-header>
			<el-main>
				<div class="content">
					<el-tabs tab-position="left" class="tab" type="card" @tab-click="tabs">
						<el-tab-pane label="工作台" style="height: 100%;">
							<work :selecttab="selecttab"></work>
						</el-tab-pane>
						<el-tab-pane label="全部" style="height: 100%;overflow: hidden;">
							<allOrder :orderId="orderId" :selecttab="selecttab" :authid="authid"></allOrder>
						</el-tab-pane>
						<el-tab-pane label="收件" style="height: 100%;overflow: hidden;"
							v-if="authid == '1' || authid == '2'">
							<receiving :indexFlag="indexFlag" :selecttab="selecttab" :authid="authid" :Recivelist="Recivelist"
								:reciveimage="imageList" :bigRfid="bigRfid" :refresh="refresh" @delindex="removeindex">
							</receiving>
						</el-tab-pane>
						<el-tab-pane label="拆包" style="height: 100%;overflow: hidden;"
							v-if="authid == '1' || authid == '2'">
							<receiving @rfid="getRfid" :indexFlag="indexFlag" :selecttab="selecttab" :authid="authid" :Recivelist="Recivelist"
								:reciveimage="imageList" :bigRfid="bigRfid" :refresh="refresh" @delindex="removeindex">
							</receiving>
						</el-tab-pane>
						<el-tab-pane label="拒收" style="height: 100%;" v-if="authid == '1' || authid == '3'">
							<rejection :selecttab="selecttab" :authid="authid"></rejection>
						</el-tab-pane>
						<el-tab-pane label="质检" style="height: 100%;" v-if="authid == '3'">
							<inspection :authid="authid" :Inspectionlist="Inspectionlist" :zhijianlist="zhijianlist"
								:refresh="refreshs">
							</inspection>
						</el-tab-pane>
						<el-tab-pane label="补录" style="height: 100%;">
							<sendAgain @rfid="getRfid" :indexFlag="indexFlag" :selecttab="selecttab" :authid="authid" :Recivelist="Recivelist"
							:reciveimage="imageList" :bigRfid="bigRfid" :refresh="refresh" @delindex="removeindex"></sendAgain>
						</el-tab-pane>
						<el-tab-pane label="上挂" style="height: 100%;">
							<printStick :indexFlag="indexFlag"></printStick>
						</el-tab-pane>
						<el-tab-pane label="组单" style="height: 100%;">
							<afterWash :afterWashlist="afterWashlist" :selecttab="selecttab" :authid="authid"></afterWash>
						</el-tab-pane>
						<el-tab-pane label="查询" style="height: 100%;">
							<searchInfo></searchInfo>
						</el-tab-pane>
						<el-tab-pane label="补差价" style="height: 100%;">
							<repairPrice></repairPrice>
						</el-tab-pane>
					</el-tabs>
				</div>
			</el-main>
		</el-container>
	</div>
</template>

<script>
import Heads from '../../components/index/header.vue'
import work from '../../components/index/work.vue'
import receiving from '../../components/index/receiving.vue'
import rejection from '../../components/index/rejection.vue'
import inspection from '../../components/index/inspection.vue'
import allOrder from '../../components/index/allOrder.vue'
import afterWash from '../../components/index/afterWash.vue'
import printStick from '../../components/index/printStick.vue'
import sendAgain from '../../components/index/sendAgain.vue'
import searchInfo from '../../components/index/searchInfo.vue'
import repairPrice from '../../components/index/repairPrice.vue'
import $ from 'jquery'
export default {
	name: 'home',
	components: {
		Heads,
		work,
		receiving,
		rejection,
		inspection,
		allOrder,
		afterWash,
		printStick,
		sendAgain,
		searchInfo,
		repairPrice
	},
	data: function () {
		return {
			orderId: '',
			authid: '',
			selecttab: '',
			Recivelist: [],
			imageList: {},
			Inspectionlist: [],
			zhijianlist: [],
			flowMode: '',
			bigRfid: '',
			refresh: false,
			refreshs: false,
			afterWashlist:[],
			indexFlag:'',
			againRfid:''
		}
	},
	created() {
		this.authid = JSON.parse(localStorage.getItem('userinfo')).authid
		console.log(this.authid, 'index')
		this.getWebSocket()
	},
	mounted() {
		document.querySelector('.container').style.height = document.documentElement.clientHeight + 'px';
		const that = this
		window.onresize = () => {
			return (() => {
				window.screenWidth = document.body.clientWidth
				// that.screenWidth = window.screenWidth
				document.querySelector('.container').style.height = document.documentElement.clientHeight + 'px';

			})()
		}
		this.$nextTick(() => {
			window.addEventListener('keyup',(e)=>{
				if(e.repeat){
					return;
				}
				if(e.keyCode == 13){
					if(this.indexFlag == '3' ||this.indexFlag == '2'){
						this.onInputOrderId(this.orderId);
						console.log(this.indexFlag);
					}
				}
				this.orderId +=  String.fromCharCode(e.keyCode);
			})
		})
		// async 

		// 		//   get不传参
		// 		getList() {
		//     getListAPI().then(res => console.log(res)).catch(err => console.log(err))
		//   },
		// 	//post传参
		//   postForm(formData) {
		//     let data = formData
		//     postFormAPI(data).then(res => console.log(res)).catch(err => console.log(err))
		//   },

		//   //async await同步调用
		//   async postForm(formData) {
		//     const postRes = await postFormAPI(formData)
		//     const putRes = await putSomeAPI({data: 'putTest'})
		//     const deleteRes = await deleteListAPI(formData.name)
		//     // 数据处理
		//     console.log(postRes);
		//     console.log(putRes);
		//     console.log(deleteRes);
		//   }
	},
	methods: {
		getRfid(rfid){
			this.againRfid = rfid;
			console.log('onInputOrderId刷新执行',this.againRfid);
            let uid = JSON.parse(localStorage.getItem('userinfo')).id;
            this.$http.post(`/rfidlist/checkRfid?recvRfid=${this.againRfid}&uid=${uid}`, null, (res) => {
				console.log('请求发送成功',res.data,'---------------');
				this.onGetOrder(res.data);
			});
            this.orderId = '';
		},
		// input失去焦点要重新获取
		// getFocus(){
		// 	setTimeout(()=>{
		// 		if(this.selecttab == 2){
		// 		this.$refs.inputOrderId.focus();
		// 	}
		// 	},100)
		// },
		async tabs(e) {
			console.log(e.index)
			this.indexFlag=e.index;
			// 如果e.index==2执行以下代码
			// if(e.index==2){
			// 	console.log('进入循环成功index为2');
			// 	// 文本框自动获取焦点
			// 	this.$refs.inputOrderId.focus();
			// }
			this.selecttab = e.index
			// if(e.index === 2){
			// console.log(222222222);
			// this.$http.post('receiving/getstatus', {} ,function (data)  {
			// 	console.log(data,'答案是');
			// })
			// }
			// if (e.index == '2') {
			// 	if (JSON.parse(localStorage.getItem('userinfo')).authid == '1') {
			// 		$.getJSON('http://127.0.0.1:19685/api/card/beginQc?uid=' + localStorage.getItem('id'), function (data) {
			// 			// eval(data);
			// 			console.log(data)
			// 		});
			// 	}
			// 	if (JSON.parse(localStorage.getItem('userinfo')).authid == '2') {
			// 		$.getJSON('http://127.0.0.1:19685/api/card/beginBag?uid=' + localStorage.getItem('id'), function (data) {
			// 			// eval(data);
			// 			console.log(data)
			// 		});
			// 	}
			// }
			// if (e.index == '3') {
			// 	$.getJSON('http://127.0.0.1:19685/api/card/beginStockOut?uid=' + localStorage.getItem('id'), function (data) {
			// 		// eval(data);
			// 		console.log(data)
			// 	});
			// }
			if(e.index == '7'){
				let obj = {
					status: '24'
				};
				await this.$http.post('/index/getOrderList',obj,(res) => {
					this.afterWashlist = res.data.list;
				})
			}
		},
		removeindex(index) {
			this.Recivelist.splice(index, 1);
		},
		onInputOrderId(orderId) {
			console.log('onInputOrderId执行',orderId);
            let uid = JSON.parse(localStorage.getItem('userinfo')).id;
            this.$http.post(`/rfidlist/checkRfid?recvRfid=${orderId}&uid=${uid}`, null, (res) => {
				console.log('请求发送成功',res.data,'---------------');
				this.onGetOrder(res.data);
			});
            this.orderId = '';
        },
		onGetOrder(data){
			console.log(111,data)
			let self = this;
				var type = data.type || '';
				console.log(type)
				self.refresh = false
				self.refreshs = false
				switch (type) {
					// Events.php中返回的init类型的消息，将client_id发给后台进行uid绑定
					case 'init':

						// 利用jquery发起ajax请求，将client_id发给后端进行uid绑定
						// $.post('./bind.php', {client_id: data.client_id}, function(data){}, 'json');
						self.$http.post('rfidlist/getclientid', { user_id: localStorage.getItem('id'), client_id: data.client_id }, (res) => {
							// if(res.code == 1){
							//     localStorage.setItem('userinfo',JSON.stringify(res.data.userinfo))
							//     localStorage.setItem('id',res.data.userinfo.id)
							//     this.$router.push('/')
							// }else{
							//     this.$message.error(res.msg)
							// }
						})
						localStorage.setItem('clientId', data.client_id)
						if (JSON.parse(localStorage.getItem('userinfo')).authid == '1') {
							$.getJSON('http://127.0.0.1:19685/api/card/beginQc?uid=' + localStorage.getItem('id'), function (data) {
								// eval(data);
								console.log(data)
							});
						}
						if (JSON.parse(localStorage.getItem('userinfo')).authid == '2') {
							$.getJSON('http://127.0.0.1:19685/api/card/beginBag?uid=' + localStorage.getItem('id'), function (data) {
								// eval(data);
								console.log(data)
							});
						}
						if (JSON.parse(localStorage.getItem('userinfo')).authid == '3') {
							$.getJSON('http://127.0.0.1:19685/api/card/beginStockOut?uid=' + localStorage.getItem('id'), function (data) {
								// eval(data);
								console.log(data)
							});
						}
						// $.getJSON('', function (data) {
						// 	eval(data);
						// });
						break;
					case 'success':
						console.log(self.selecttab)
						if (self.selecttab == '3') {
							self.Recivelist =data.data
							// self.$message.error('请点击到收件页面进行扫码111')
						}else if(self.selecttab == '2'){
							self.Recivelist =data.data
							// self.$message.error('请点击到收件页面进行扫码2323')
						} else {
							self.$message.error('请点击到收件页面进行扫码111')
							// self.Recivelist =data.data
							// setTimeout(function () {
							// 	$.getJSON('http://127.0.0.1:19685/api/card/pushQcGoodsId?goodsId=' + self.Recivelist[0].id, function (data) {
							// 		// eval(data);
							// 		console.log(data)
							// 	});
							// }, 2000)

							console.log(self.Recivelist)
						}
						break;
					case 'success2':
						if (self.selecttab !== '3') {
							self.$message.error('请点击到收件页面进行扫码')
						} else {
							// self.Recivelist = []
							self.Recivelist.unshift(JSON.parse(e.data).data)
							console.log(self.Recivelist)
						}
						break;
					// 当mvc框架调用GatewayClient发消息时直接alert出来
					case 'success3':
						console.log(self.selecttab)
						if (self.selecttab !== '3') {
							self.$message.error('请点击到质检页面进行扫码')
						} else {
							self.Inspectionlist = JSON.parse(e.data).data
							console.log(self.Inspectionlist)
						}
						break
					case 'error':
						self.$message.info(data.message)
						break;
					case 'error2':
						if (self.selecttab !== '3') {
							self.$message.error('请点击到收件页面进行扫码')
						} else {
							self.$message.info(data.message)
							self.bigRfid = data.data.rfid
							// localStorage.setItem('bigRfidMax', data.data.rfid)

							if (localStorage.getItem('bigRfid')) {
								if (localStorage.getItem('bigRfid') !== data.data.rfid) {
									localStorage.setItem('bigRfid1', data.data.rfid)
								}
							} else {
								localStorage.setItem('bigRfid', data.data.rfid)
							}
							var smallRfid = [];
							if (self.Recivelist.length > 0) {
								const seen = new Set();
								self.Recivelist.forEach(item => {
									const key = JSON.stringify(item);
									if (!seen.has(key)) {
										seen.add(key);
										smallRfid.push(item);
									}
								});
							}
							console.log(smallRfid)
							var str = ''
							if (smallRfid.length > 0) {
								str = smallRfid.map(function (elem) {
									return elem.id;
								}).join(",");
							}

							console.log(str)
							self.$http.post('rfidlist/bindGigRfid', { uid: localStorage.getItem('id'), bagRfid: localStorage.getItem('bigRfid'), qcRfid: str }, (res) => {
								if (res.code == 1) {
									if (str !== '' && localStorage.getItem('bigRfid1')) {
										localStorage.setItem('bigRfid', localStorage.getItem('bigRfid1'))
										localStorage.removeItem('bigRfid1')
									}

								} else {
									// self.$message.error(res.msg)
								}

							})
							self.Recivelist = []
						}

						break;
					case 'bindimage':
						// self.$message.success('绑定成功')
						console.log(JSON.parse(e.data))
						self.zhijianlist = JSON.parse(e.data).data.image
						console.log(self.zhijianlist)
						// self.zhijianlist = 
						break;
					case 'refresh':
						console.log(e.data)
						console.log(JSON.parse(e.data))
						self.refresh = true
						console.log(self.refresh)
						break;
					case 'bindqau':
						self.$message.success('绑定成功')
						self.refreshs = true
						break;
					default:
						console.log(data)
						self.imageList = data
				}
			},
			getWebSocket() {
			// 'ws://'+
			var ws = new WebSocket(localStorage.getItem('httpip'));
			// var ws = new WebSocket('wss://xcx.jiekee.com/wss');
			// +localStorage.getItem('httpip')

			ws.onopen = function (event) {
				console.log('WebSocket 连接已打开');
			};

			ws.onmessage =  (e) => {
				try{
					console.log(e);
					var data=JSON.parse(e.data);
					this.onGetOrder(data);
				}catch(error){
					console.log(error);
				}
		}
	}
		},
}
</script>

<style>
.container {
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.el-header {
	color: #333;
}

.el-main {
	color: #333;
	padding: 20px 80px !important;
	background: #eee;
}

.content {
	height: 100%;
}

.tab {
	/* background: #fff; */
	height: 100%;
}

.el-tabs__header {
	background: #fff;
	height: 100%;
}

.el-tabs__item {
	border: none !important;
	height: 73px !important;
	line-height: 73px !important;
	width: 82px !important;
	font-size: 14px !important;
}

.el-tabs__item.is-active {
	background: #d4e9ff;
	color: #333 !important;
}

.el-tabs__nav {
	border: none !important;
}

.el-tabs__content {
	/* background: #ddd; */
	height: 100%;
	padding: 0 15px !important;
}
/* 隐藏扫码枪输入框 */
/* .input_content{
	height: 0;
	margin-left: 30px;
} */
</style>