<template>
	<div class="work_content" ref="work_content">
		<el-row :gutter="10">
			<el-col :span="6">
				<div class="work_one">
					<div class="work_o_t">
						<div class="work_basic">
							<p>姓名：{{ userinfo.realname }}</p>
							<p>联系方式：{{ userinfo.tel }}</p>
						</div>
						<div class="work_basic">
							<p>当前帐号：{{ userinfo.username }}</p>
							<p>工厂名称：{{ userinfo.name }}</p>
						</div>
					</div>
					<div class="work_o_c">
						<div class="work_status">
							工作状态：<span style="color: rgba(34,215,0,1);" v-if="userinfo.status == 1">工作中</span>
							<span style="color: rgba(255,0,0,1);" v-if="userinfo.status == 0">休息中</span>
						</div>
						<div class="time">
							<p>系统时间：</p>
							<p>{{formattedDate}}</p>
						</div>
					</div>
				</div>
			</el-col>
			<el-col :span="6">
				<div class="work_one">
					<div class="work_o_t">
						<p style="font-weight: 600;font-size: 15px;">工作出勤</p>
					</div>
					<div class="work_o_c">
						<div class="work_Attendance">
							<div class="flex-center">
								本月出勤天数：<p class="nums">{{ day.month }}</p>
							</div>
						</div>
						<div class="work_Attendance">
							<div class="flex-center">
								上月出勤天数：<p class="last_nums">{{ day.lastmonth }}/{{ day.lastday }}天</p>
							</div>
						</div>
					</div>
				</div>
			</el-col>
			<el-col :span="6">
				<div class="work_one">
					<div class="work_o_t">
						<p style="font-weight: 600;font-size: 15px;">出勤工时</p>
					</div>
					<div class="work_o_c">
						<div class="work_Attendance">
							<div class="flex-center">
								本月出勤天数：<p class="nums">{{ time.day }}</p><span
									style="margin-top: 10px;margin-left: 5px;">小时</span>
							</div>
						</div>
						<div class="work_Attendance">
							<div class="flex-center">
								上月出勤天数：<p class="last_nums">{{ time.count }}小时</p>
							</div>
						</div>
					</div>
				</div>
			</el-col>

		</el-row>
		<!-- <el-row :gutter="10">
			<el-col :span="6">
				<div class="work_one">
					<div class="work_o_t">
						<p style="font-weight: 600;font-size: 15px;">质检数量</p>
					</div>
					<div class="work_o_c">
						<div class="work_Attendance">
							<div class="flex-center">
								本月检数量：<p class="nums">23</p>
							</div>
						</div>
						<div class="work_Attendance">
							<div class="flex-center">
								上月质检数量：<p class="last_nums">230</p>
							</div>
						</div>
					</div>
				</div>
			</el-col>
			<el-col :span="6">
				<div class="work_one">
					<div class="work_o_t">
						<p style="font-weight: 600;font-size: 15px;">售后单数</p>
					</div>
					<div class="work_o_c">
						<div class="work_Attendance">
							<div class="flex-center">
								本月售后量：<p class="nums">65</p>
							</div>
						</div>
						<div class="work_Attendance">
							<div class="flex-center">
								上月售后量：<p class="last_nums">333</p>
							</div>
						</div>
					</div>
				</div>
			</el-col>
		</el-row> -->
		<div class="visualization">
			<!-- <p>最近30天曲线</p> -->
			<el-date-picker v-model="timeDate" type="daterange" range-separator="-" start-placeholder="开始日期"
				end-placeholder="结束日期" @change="getNum" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
				:default-time="['00:00:00', '23:59:59']">
			</el-date-picker>
			<div class="visualization_content flex-center">
				<!-- <div class="tubiao">
					<chart></chart>
				</div> -->
				<ul class="showNums">
					<li>
						<p>{{ Nums.sort_num }}</p>
						<p>分拣数量</p>
					</li>
					<li>
						<p>{{ Nums.qua_num }}</p>
						<p>质检数量</p>
					</li>
					<li>
						<p>{{ Nums.reje_num }}</p>
						<p>拒收数量</p>
					</li>
				</ul>
			</div>
		</div>




	</div>
</template>

<script>
import chart from '../chart/chart.vue'
export default {
	name: 'work',
	components: {
		chart
	},
	props: {
		selecttab: {
			type: [Number, String],
			default: ''
		}
	},
	data: function () {
		return {
			timeDate: [],
			userinfo: {},
			day: {},
			time: {},
			Nums: {},
			date: new Date()
		}
	},
	watch: {
		selecttab: {
			handler(newValue, oldValue) {
				console.log(newValue)
				if (newValue == '0') {
					this.Info()
					this.timeDate = []
					this.getNowTime()
					this.getNum()
					// this.$nextTick(() => {
					// 	this.$refs.inputOrderId.focus();
					// });
				}
			}
		}
	},
	mounted() {
		this.Info()
		this.getNowTime()
		this.getNum()
		var _this = this;
		this.timer = setInterval(function () {
			_this.date = new Date();
		}, 1000);
	},
	computed: {
		formattedDate: function () {
			var date = this.date;
			var year = date.getFullYear();
			var month = (date.getMonth() + 1).toString().padStart(2, '0');
			var day = date.getDate().toString().padStart(2, '0');
			var hours = date.getHours().toString().padStart(2, '0');
			var minutes = date.getMinutes().toString().padStart(2, '0');
			var seconds = date.getSeconds().toString().padStart(2, '0');

			return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
		}
	},
	methods: {
		Info() {
			this.$http.post('/index/index', { id: localStorage.getItem('id') }, (res) => {
				console.log(res)
				if (res.code == 1) {
					this.userinfo = res.data.unserinfo
					this.day = res.data.day
					this.time = res.data.time
					localStorage.setItem('userinfo', JSON.stringify(this.userinfo))
				}
			})
		},
		getNowTime() {
			var now = new Date();
			var year = now.getFullYear(); //得到年份
			var month = now.getMonth(); //得到月份
			var date = now.getDate(); //得到日期
			month = month + 1;
			month = month.toString().padStart(2, "0");
			date = date.toString().padStart(2, "0");
			var defaultDate = `${year}-${month}-${date}`;
			this.timeDate.push(defaultDate)
			this.timeDate.push(defaultDate)
			console.log(this.timeDate)
		},
		filterTime(time) {
			var date = new Date(time);
			var y = date.getFullYear();
			var m = date.getMonth() + 1;
			m = m < 10 ? "0" + m : m;
			var d = date.getDate();
			d = d < 10 ? "0" + d : d;
			var h = date.getHours();
			h = h < 10 ? "0" + h : h;
			var minute = date.getMinutes();
			minute = minute < 10 ? "0" + minute : minute;
			var s = date.getSeconds();
			s = s < 10 ? "0" + s : s;
			return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + s;
		},
		getNum() {
			console.log(this.timeDate)
			var parmas = {
				id: localStorage.getItem('id'),
				starttime: this.timeDate == '' || this.timeDate == null ? '' : this.timeDate[0],
				endtime: this.timeDate == '' || this.timeDate == null ? '' : this.timeDate[1]
			}
			this.$http.post('/index/statistics', parmas, (res) => {
				console.log(res)
				if (res.code == 1) {
					this.Nums = res.data
				}
			})
		}
	}
}
</script>

<style>
.el-row {
	margin-bottom: 20px;
}

.work_content {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.work_one {
	background: #fff;
	height: 3.1rem;
	padding: .25rem;
	color: rgba(0, 0, 0, 0.6);
	font-size: .175rem;
	display: flex;
	flex-direction: column;
}

.work_o_t {
	border-bottom: 1px solid #ddd;
	height: .6875rem;
}

.flex-center {
	display: flex;
	align-items: center;
}

.work_basic {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: .1rem;
}

.work_basic p {
	width: 50%;
}

.work_o_c {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-top: .125rem;
}

.time {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.work_Attendance {
	height: 50%;
	display: flex;
	align-items: flex-end;
}

.nums {
	font-size: .4rem;
}

.last_nums {
	font-size: .225rem;
}

.visualization {
	background: #fff;
	padding: .125rem .25rem;
	color: rgba(0, 0, 0, 0.6);
	font-size: .2rem;
	flex: 1;
}

.visualization_content {
	width: 100%;
}

.tubiao {
	flex: 1;
}

.showNums {
	display: flex;
	width: 100%;
	margin-top: 0.25rem;
}

.showNums li {
	width: 20%;
	height: 3.075rem;
	font-size: 0.45rem;
	color: #625555;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 1px solid #ddd;
	margin-right: 0.4rem;
	line-height: 1.3rem;
}

.showNums li p:nth-of-type(1) {
	font-size: 0.6rem;

}
</style>