<template>
    <div class="orderInfo" v-if="orderid !== ''" style="position: relative;">
        <!-- <div class="introduce"> -->
        <div class="order_basic">
            <div class="order_basic_top">
                基本信息
            </div>
            <ul class="basic_content">
                <li>RFID:{{ orderid.rfid }}</li>
                <li>订单编号:{{ orderid.number }}</li>
                <li>名称:{{ orderid.title }}</li>
				<li style="border-right: none;">是否加急: <span v-if="orderid.urgent==2" style="color:red">是</span> <span v-if="orderid.urgent==1">否</span></li>
                <li >分类:{{ orderid.type_title }}</li>
                <li>姓名:{{ orderid.nickname }}</li>
                <li>电话:{{ orderid.mobile }}</li>
                <li style="border-right: none;">地区:{{ orderid.city }}</li>
                <li >时间:{{ orderid.createtime }}</li>
                <li>水洗唛码:{{ orderid.id }}</li>
                <li @click="openClothingNote" style="width: 282px;  white-space: nowrap; overflow: hidden; text-overflow: ellipsis">衣物注意事项:{{ orderid.content2 }}</li>
                <li @click="openNote" style="width: 282px;  white-space: nowrap; overflow: hidden; text-overflow: ellipsis">备注:{{ orderid.content }}</li>
                <li v-if="orderid.status == 31">拒收时间:{{ orderid.rejection_time }}</li>
                <li v-if="orderid.status == 31" style="width: 282px;  white-space: nowrap; overflow: hidden; text-overflow: ellipsis">拒收原因:{{ orderid.rejection_content }}</li>
                <li v-if="orderid.return_content">退回时间:{{ orderid.return_content }}</li>
                <li v-if="orderid.return_content">退回原因:{{ orderid.return_time }}</li>
            </ul>
        </div>
        <div v-if="indexFlag == 3" style="overflow-y: auto;flex: 1;">
            <div class="introduce_content" style="margin-bottom: .25rem;">
                <div class="order_basic_top" style="background: #ffa100;">
                    收件上传图片
                </div>
                <div class="upload">
                    <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="">
                        <div class="prev">
                            <el-button size="medium" type="primary" @click="prev()">上一张</el-button>
                            <el-button size="medium" type="primary" @click="next()">下一张</el-button>
                        </div>
                    </el-dialog>
                </div>
            </div>
            <div class="photoContent">
                <div v-if="orderCategory == 'receiving'" class="photo">
                    <div class="photo-grid">
                        <div>
                            <video  class="videoNow" ref="video" width="300" height="200" autoplay></video>
                            <canvas class="canvas" ref="canvas" width="3264" height="2448"></canvas>
                        </div>
                        <div class="photo-item" v-for="(photo, index) in Chaibaohotos" :key="index">
                            <img @click="toBig(index)" :src="photo.url" :alt="`Captured Image ${index + 1}`" />
                            <button class="delete-btn" @click="deletePhoto(index)">x</button>
                        </div>
                    </div>
                </div>
                <div class="buttonContent" v-if="orderCategory == 'receiving'">
                    <div style="margin-bottom: 50px;"><el-button class="elButton"  type="warning" @click="captureImage" v-if="authid == '1'">拍照</el-button></div>
                    <div><el-button class="elButton" type="info" @click="uploadPhotos" v-if="authid == '1'">上传照片</el-button></div>
                </div>
            </div>
            <div ref="imageContainer" v-show="showBigPhoto" style=" z-index: 3; position: absolute; top: 100px;" >
                <div ref="imageWrapper">
                    <!-- <div  class="photoInfo-btn" @click="changPhoto">x</div> -->
                    <img :src="photoUrl" ref="image" @wheel="handleWheel" alt="">
                </div>
            </div>
            <div class="toPrintContent" v-show="orderCategory == 'receiving' && showPrint">
                <div id="printableArea" ref="printArea">
                    <div style="display: inline-block;">
                        <div>{{orderid.number}}</div>
                        <img style="margin-left: 20px; ;" :src="this.imgUrl" alt="">
                        <div style="margin-left: 50px;">{{ orderid.id }}</div>
                    </div>
                    <div style="display: inline-block;">
                        <div v-show="!form.delivery">
                            <div>
                                &nbsp;&nbsp;<span v-if="orderIndex">[{{ orderLength }}-{{ orderIndex }}]</span>
                                <span v-else>[{{ orderLength }}-1]</span>
                            </div>
                            <div>
                                <span>&nbsp;&nbsp;入厂时间:{{ entryTime }}</span>
                                <span>备注:{{form.name}}</span>
                            </div>
                            <div>
                                <canvas style="display: none;" ref="barcodeCanvas"></canvas>
                                &nbsp;&nbsp;<span>洗护类别:{{ form.cloth }}</span>
                            </div>
                            <div>
                                &nbsp;&nbsp;<span>颜色:{{ form.resource }}{{ colorContent }}</span>&nbsp;&nbsp;
                                &nbsp;&nbsp;<span>附件:{{ form.resource3 }}{{ otherContent }}</span>
                            </div>
                            <div>
                                &nbsp;&nbsp;<span style="padding-right:10px">瑕疵:{{ form.type[0] }}&nbsp;{{ form.type[1] }}&nbsp;{{ form.type[2] }}&nbsp;{{ form.type[3] }}&nbsp;{{ form.type[4] }}&nbsp;{{ flawContent }}</span>
                            </div>
                        </div>
                        <div v-show="form.delivery">
                            <span>无衣物</span>
                        </div>
                    </div>
                </div>
                <div>
                    <el-button class="elButton" type="primary" @click="goPrint" v-if="authid == '1'">打印水洗唛</el-button>
                </div>
            </div>
            <el-form :rules="rules" ref="form" :model="form" label-width="80px" v-show="orderCategory == 'receiving' && showPrint">
                <el-form-item label="衣物:" :required=true>
                    <el-radio-group v-model="form.cloth">
                        <el-radio v-for="(item,index) in options" :key="index" :label="item.value">{{ item.label }}</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="颜色:" :required=true>
                    <el-radio-group :min="1" v-model="form.resource">
                        <el-radio v-for="(item,index) in colorList" :key="index" :label="item" :value="index"></el-radio>
                        <input placeholder="自定义颜色" style="margin-left: 20px;" v-model="colorContent" type="text">
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="瑕疵:" :required=true>
                    <el-checkbox-group :max="5" v-model="form.type">
                        <el-checkbox v-for="(item,index) in flawList" :key="index" :label="item" :value="index"></el-checkbox>
                        <input placeholder="自定义瑕疵" style="margin-left: 20px;" v-model="flawContent" type="text">
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="附件:" :required=true>
                    <el-radio-group v-model="form.resource3">
                        <el-radio v-for="(item,index) in fileList" :label="item" :key="index" :value="index"></el-radio>
                        <input placeholder="自定义附件" style="margin-left: 20px;" v-model="otherContent" type="text">
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="备注:" prop="name">
                    <el-input v-model="form.name" placeholder="请输入10字内备注"></el-input>
                </el-form-item>
                <el-form-item label="无衣物">
                    <el-switch v-model="form.delivery"></el-switch>
                </el-form-item>
            </el-form>
            <div class="introduce_content"
                v-if="orderCategory == 'inspection' || (orderCategory == 'allOrder' && orderid.status == 21)">
                <div class="order_basic_top" style="background: #ff7878;">
                    质检上传图片
                </div>
                <div class="upload">
                    <div class="images" v-for="(item, index) in orderid.images_qua" :key="index">
                        <img :src="httpimg + item" alt="" @click="handlePictureCardPreviews(index)">
                        <i class="el-icon-error" v-if="orderCategory == 'inspection' && authid == '3' && !zhijian"
                            @click="delerejection(index)"></i>
                    </div>
                    <el-dialog :visible.sync="dialogVisibles">
                        <img width="100%" :src="dialogImageUrls" alt="">
                        <div class="prev">
                            <el-button type="primary" @click="prevs()">上一张</el-button>
                            <el-button type="primary" @click="nexts()">下一张</el-button>
                        </div>
                    </el-dialog>
                </div>
            </div>
            <!-- </div> -->
        </div>


        <div class="introduce_footer" v-if="orderCategory !== 'allOrder'">
            <div style="margin: 0.2rem auto 0;display: flex;align-items: center;justify-content: center;"
                v-if="orderCategory == 'receiving'">
                <!-- <el-button class="save" type="info" @click="uploadPhotos" v-if="authid == '1'">点击上传照片</el-button> -->
                <!-- <el-button class="save" type="primary" @click="goPrint" v-if="authid == '1'">打印水洗唛</el-button> -->
                <!-- <el-button class="save" type="warning" @click="Unbind('解绑成功')" v-if="authid == '1'">解绑</el-button> -->
                <!-- <el-button class="save" type="primary" @click="Unbind('收件成功')">可收件</el-button> -->
                <!-- <el-button class="save" type="warning" @click="captureImage" v-if="authid == '1'">点击拍照</el-button> -->
                <!-- <el-button class="save" type="danger" @click="updateCategoryStatu" v-if="authid == '1'">拒收件</el-button> -->
            </div>
            <div style="margin: 0.2rem auto 0;display: flex;align-items: center;justify-content: center;"
                v-if="orderCategory == 'inspection' && authid == '3'">
                <el-button class="save" type="warning" @click="bindJie">解绑</el-button>
                <!-- <el-button class="save" type="primary" @click="Unbind('质检合格')">合格</el-button> -->
                <el-button class="save" type="danger" @click="backStatusStatus">退回</el-button>
            </div>
        </div>



        <!-- </div> -->
        <el-dialog :visible.sync="updateCategory" title="拒收原因" :show-close="false">
            <div class="flexPullDown">
                <div class="ioo">拒收类型<div style="font-weight: 600;">：</div>
                </div>
                <select v-model="selectedItem" @change="changePull" class="pullDown">
                    <option value="">请选择拒收类型</option>
                    <option v-for="(item, index) in pullDown" :key="index" :value="item.id">{{ item.name }}</option>
                </select>

            </div>
            <el-input type="textarea" :rows="4" placeholder="请输入拒收原因" v-model="textarea">
            </el-input>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="save()">保 存</el-button>
            </span>
        </el-dialog>

        <el-dialog :visible.sync="backStatus" title="退回原因" :show-close="false">

            <div class="flexPullDown">
                <div class="ioo">退回原因<div style="font-weight: 600;">：</div>
                </div>
                <select v-model="selectedItemText" @change="changePull" class="pullDown">
                    <option value="">请选择退回类型</option>
                    <option v-for="(item, index) in pullDown" :key="index" :value="item.id">{{ item.name }}</option>
                </select>

            </div>

            <el-input type="textarea" :rows="4" placeholder="请输入退回原因" v-model="textareas">
            </el-input>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="back()">保 存</el-button>
            </span>
        </el-dialog>

        <el-dialog title="" :visible.sync="showStatus" width="20%" center :show-close="false">
            <div class="centers">
                <i class="el-icon-check"></i>
                <p>{{ showText }}</p>
            </div>
        </el-dialog>
        <el-dialog title="" :visible.sync="showStatus" width="20%" center :show-close="false">
            <div class="centers">
                <i class="el-icon-check"></i>
                <p>{{ showText }}</p>
            </div>
        </el-dialog>
    </div>
    <div style="display: flex;height: 100%;justify-content: center;align-items: center;" v-else>
        <el-empty description="暂无订单"></el-empty>
    </div>
</template>

<script>
import JsBarcode from 'jsbarcode';
import axios from 'axios'
import dialogcontent from './dialogcontent.vue'
import $ from 'jquery'
import Header from './header.vue'
import OSS from 'ali-oss';
import { isShallow } from 'vue';
export default {
    props: {
        giveStatus: {
            type: [Number, String],
            default: ''
        },
        orderCategory: {
            type: String,
            default: 'receiving'
        },
        orderid: {
            type: [Object, String],
            default: ''
        },
        authid: {
            type: String,
            default: ''
        },
        zhijian: {
            type: Boolean,
            default: false
        },
        inputmax: {
            type: String,
            default: ''
        },
        rejectionFlag:{
            type:Boolean,
            default:true
        },
        indexFlag:{
            type:String
        },
        orderLength:{
            type:[String,Number]
        },
        orderIndex:{
            type:[String,Number],
            default:'1'
        }
    },
    components: {
        dialogcontent
    },
    watch: {
        orderid: {
            handler(newValue, oldValue) {
                // console.log(newValue, oldValue);
                this.updateCategory = false;
                this.getEntryTime();
                this.capturedPhotos = [];
                this.ossUrls = [];
                this.form.cloth = '';
                this.form.resource = '';
                this.form.type = [];
                this.form.resource3 = '';
                this.form.name = '无';
                this.flawContent = '';
                this.colorContent = '';
                this.otherContent = '';
                this.showPrint = false
            },
            immediate: true,
            deep: true
            // this.orderid = this.orderid
            
        },
        rejectionFlag:{
            handler(newValue, oldValue) {
                this.updateCategoryStatu();
            },
            deep: true
        }
    },
    data: function () {
        return {
            dialogImageUrl: '',
            dialogImageUrls: '',
            dialogVisible: false,
            dialogVisibles: false,
            input: '',
            selectedItem: '',
            selectedItemText: '',
            updateCategory: false,
            showStatus: false,
            showText: '',
            // value: '',
            fileList: [],
            fileLists: [],
            selected: '',
            selectImg: 0,
            textarea: '',
            textareas: '',
            backStatus: false,
            backAdd: false,
            httpimg: '',
            pullDown: [],
            videoStream: null,
            Chaibaohotos: [],
            capturedPhotos: [], //存储拍摄的照片信息
            ossClient: null,
            ossFile:{},
            photoUrl:'',
            ossUrls:[], //oss返回的地址数组
            isFlag:true,
            form: {
                name: '',
                delivery: false,
                type: [],
                resource: '',
                resource3: '',
                cloth:'',
                delivery:false
            },
            rules: {
                name:[
                    { max:10, message: '请填10字以内备注', trigger: 'blur' }
                ]
            },

            fileList:['盒子', '手提袋', '尘袋', '身份卡', '挂饰', '包内遗留物', '包内胆', '包撑', '鞋撑', '单层帽', '毛帽边', '袖筒', '有袖内胆', '无袖内胆', 
            '下身', '毛领', '衣领', '鞋带', '帽子', '另一条袖带', '另一条肩带', '领子袋', '另一条毛领', '毛边', '另一条毛边', '毛袖', '另一条毛袖', '内胆', 
            '活袖', '另一条活袖', '帽绳', '腰绳', '底绳', '活绳', '毛球', '另一个毛球', '蝴蝶结', '另一条鞋带', '另一条鞋垫', '下摆', '扣子', '垫扣', '拉链头','无'],
            colorList:['米白', '米黄', '浅灰色', '青灰色', '墨绿', '浅蓝', '天蓝', '海军蓝', '湖蓝', '藏青', '雪青色', '浅白', 
             '奶白', '灰白', '黑色', '灰黑', '黑夹灰', '橙色', '驼色', '砖色', '花色', '格子', '黑白格', '千鸟格', '条纹', '卡通图案', '白色', '白夹黑', 
             '黄色', '红色', '桃红', '粉色', '蓝色', '深蓝', '草绿', '军绿', '灰色', '银灰色', '棕色', '咖啡色', '卡其色', '杏色', '紫色', '紫红'],
            flawList:['掉扣子', '扣掉漆', '折痕', '印花开裂', '污渍', '磨损', '磨破', '变形', '发黄', '渍尽洗', '油渍', 
            '泥渍', '黑渍', '笔渍', '茶渍', '白渍', '墨渍', '果渍', '酒渍', '黄渍', '血渍', '奶渍', '红渍', '绿渍', '有渍', '红蜡烛渍', '咖啡渍', 
            '机油渍', '乌鸡渍', '油漆渍', '脏尽洗', '白渍尽去', '黄渍有印', '甩色', '搭色', '串色', '阴阳色', '染色', '色花', '多处搭色','压胶','涂层','起毛','起球',
            '划痕','压胶破损','涂层破损','日照褪色','褪色','开胶','折痕开裂','皮面开裂','皮面起泡','霉斑','锈迹','纽扣掉色','少色','褪色','易褪色','光泽差','咬色',
            '有孔','有烂','甩丝','磨花','勾线','反光','磨烂','修补过','掉绒','蛀洞','少商标','无'],
            imgUrl:'',
            entryTime:'',
            showBigPhoto:false,
            scale: 1,
            isMouseOverImage: false,
            flawContent:'',
            colorContent:'',
            otherContent:'',
            // value:[],
            options:[
                {value:'卫衣/上装/一档',label:'卫衣'},
                {value:'T恤/上装/一档',label:'T恤'},
                {value:'连衣裙/上装/一档',label:'连衣裙'},
                {value:'衬衫/上装/一档',label:'衬衫'},
                {value:'半身裙/上装/一档',label:'半身裙'},
                {value:'睡衣/上装/一档',label:'睡衣'},
                {value:'瑜伽服/上装/一档',label:'瑜伽服'},
                {value:'连帽/上装/一档',label:'连帽'},
                {value:'Polo/上装/一档',label:'Polo'},
                {value:'牛仔/上装/一档',label:'牛仔'},
                {value:'马甲背心/上装/一档',label:'马甲背心'},
                {value:'西服/上装/二档',label:'西服'},
                {value:'风衣/上装/二档',label:'风衣'},
                {value:'棒球服/上装/二档',label:'棒球服'},
                {value:'机车服/上装/二档',label:'机车服'},
                {value:'羽绒服/上装/二档',label:'羽绒服'},
                {value:'夹克衫/上装/二档',label:'夹克衫'},
                {value:'滑雪服/上装/二档',label:'滑雪服'},
                {value:'棉服/上装/二档',label:'棉服'},
                {value:'汉服/上装/二档',label:'汉服'},
                {value:'骑行服/上装/二档',label:'骑行服'},
                {value:'摇粒绒/上装/二档',label:'摇粒绒'},
                {value:'冲锋衣/上装/二档',label:'冲锋衣'},
                {value:'貂皮/上装/三档',label:'貂皮'},
                {value:'皮衣/上装/三档',label:'皮衣'},
                {value:'毛呢/上装/三档',label:'毛呢'},
                {value:'大衣/上装/三档',label:'大衣'},
                {value:'皮夹克/上装/三档',label:'皮夹克'},
                {value:'真丝衬衫/上装/三档',label:'真丝衬衫'},
                {value:'羊绒大衣/上装/三档',label:'羊绒大衣'},
                {value:'羊绒毛衣/上装/三档',label:'羊绒毛衣'},

                {value:'旗袍/上装/三档',label:'旗袍'},
                {value:'牛仔裤/下装/一档',label:'牛仔裤'},
                {value:'工装裤/下装/一档',label:'工装裤'},
                {value:'棉麻裤/下装/一档',label:'棉麻裤'},
                {value:'卫裤/下装/一档',label:'卫裤'},
                {value:'短裤/下装/一档',label:'短裤'},
                {value:'棉裤/下装/一档',label:'棉裤'},
                {value:'打底裤/下装/一档',label:'打底裤'},
                {value:'睡裤/下装/一档',label:'睡裤'},
                {value:'瑜伽裤/下装/一档',label:'瑜伽裤'},
                {value:'休闲裤/下装/一档',label:'休闲裤'},
                {value:'机车裤/下装/二档',label:'机车裤'},
                {value:'西裤/下装/二档',label:'西裤'},
                {value:'呢子裙/下装/二档',label:'呢子裙'},
                {value:'皮裤/下装/一档',label:'皮裤'},

                {value:'运动鞋/鞋类/一档',label:'运动鞋'},
                {value:'板鞋/鞋类/一档',label:'板鞋'},
                {value:'帆布鞋/鞋类/一档',label:'帆布鞋'},
                {value:'豆豆鞋/鞋类/一档',label:'豆豆鞋'},
                {value:'面包鞋/鞋类/一档',label:'面包鞋'},
                {value:'网面鞋/鞋类/一档',label:'网面鞋'},
                {value:'老爹鞋/鞋类/一档',label:'老爹鞋'},
                {value:'椰子鞋/鞋类/一档',label:'椰子鞋'},
                {value:'洞洞鞋/鞋类/一档',label:'洞洞鞋'},
                {value:'沙滩鞋/鞋类/一档',label:'沙滩鞋'},
                {value:'凉鞋/鞋类/一档',label:'凉鞋'},
                {value:'拖鞋/鞋类/一档',label:'拖鞋'},
                {value:'休闲鞋/鞋类/一档',label:'休闲鞋'},
                {value:'布鞋/鞋类/一档',label:'布鞋'},
                {value:'球鞋/鞋类/二档',label:'绒面鞋'},
                {value:'翻毛鞋/鞋类/二档',label:'翻毛鞋'},
                {value:'雪地靴/鞋类/二档',label:'雪地靴'},
                {value:'小白鞋/鞋类/二档',label:'小白鞋'},
                {value:'皮鞋/鞋类/二档',label:'皮鞋'},
                {value:'马丁鞋/鞋类/二档',label:'马丁靴'},
                {value:'高跟鞋/鞋类/二档',label:'高跟鞋'},

                {value:'床单/家纺/一档',label:'床单'}, 
                {value:'被罩/家纺/一档',label:'被罩'}, 
                {value:'枕套/家纺/一档',label:'枕套'}, 
                {value:'窗帘/家纺/二档',label:'窗帘'},
                {value:'地垫/家纺/二档',label:'地垫'},
                {value:'枕头/家纺/二档',label:'枕头'},
                {value:'沙发套/家纺/二档',label:'沙发套'},
                {value:'沙发垫/家纺/二档',label:'沙发垫'},
                {value:'床褥/家纺/二档',label:'床褥'},
                {value:'床笠/家纺/三档',label:'床笠'},
                {value:'夏凉被/家纺/三档',label:'夏凉被'},
                {value:'床靠/家纺/三档',label:'床靠'},
                {value:'毯子/家纺/三档',label:'毯子'},
                {value:'地毯/家纺/三档',label:'地毯'},

                {value:'帽子/其他/一档',label:'帽子'},
                {value:'围巾/其他/二档',label:'围巾'},
                {value:'手套/其他/二档',label:'手套'},
                {value:'围裙/其他/二档',label:'围裙'},
                {value:'毛绒玩具/其他/二档',label:'毛绒玩具'},
                {value:'抱枕/其他/二档',label:'抱枕'},
                {value:'帆布包/其他/二档',label:'帆布包'},
                {value:'编织包/其他/二档',label:'编织包'},
                {value:'披肩/其他/二档',label:'披肩'},
                {value:'领带/其他/二档',label:'领带'},
                {value:'皮质包/其他/三档',label:'皮质包'},

                {value:'化学纤维/材料品类/一档',label:'化学纤维'},
                {value:'再生纤维/材料品类/一档',label:'再生纤维'},
                {value:'合成纤维/材料品类/一档',label:'合成纤维'},
                {value:'粘胶纤维/材料品类/一档',label:'粘胶纤维'},
                {value:'天丝/材料品类/一档',label:'天丝'},
                {value:'锦纶/材料品类/一档',label:'锦纶'},
                {value:'晴纶/材料品类/一档',label:'晴纶'},
                {value:'丙纶/材料品类/一档',label:'丙纶'},
                {value:'氨纶/材料品类/一档',label:'氨纶'},
                {value:'天然纤维/材料品类/二档',label:'天然纤维'},
                {value:'植物纤维/材料品类/二档',label:'植物纤维'},
                {value:'动物纤维/材料品类/二档',label:'动物纤维'},
                {value:'棉/材料品类/二档',label:'棉'},
                {value:'麻/材料品类/二档',label:'麻'},
                {value:'丝/材料品类/二档',label:'丝'},
                {value:'毛/材料品类/二档',label:'毛'},
                {value:'动物纤维/材料品类/三档',label:'动物纤维'},
                {value:'丝/材料品类/三档',label:'丝'},
                {value:'毛/材料品类/三档',label:'毛'},
                {value:'皮/材料品类/三档',label:'皮'},
            ],
            printUrl:null,
            showPrint:false,
        }
    },
    mounted() {
        window.addEventListener('keyup', this.handleKeyup);
        this.configureOSSClient();
        console.log(this.zhijian)
        this.httpimg = localStorage.getItem('httpimg')
        this.$nextTick(() => {
            if(window == undefined){
                return;
            }else if(this.$refs.image == undefined){
                return;
            }else{
                
                this.$refs.image.addEventListener('mouseenter', this.handleMouseEnter);
                this.$refs.image.addEventListener('mouseleave', this.handleMouseLeave);
            }
        });
        
    },
    created() { 
        this.handleKeyup = this.handleKeyup.bind(this);
    },
    updated(){
        this.startCamera();
        this.generateBarcode();
        if(this.$refs.image == undefined){
            return;
        }else{
            this.$refs.image.addEventListener('mouseenter', this.handleMouseEnter);
            this.$refs.image.addEventListener('mouseleave', this.handleMouseLeave);
        }
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.handleKeyup); 
        if(this.$refs.image == undefined){
            return
        }else{
            this.$refs.image.removeEventListener('mouseenter', this.handleMouseEnter);
            this.$refs.image.removeEventListener('mouseleave', this.handleMouseLeave);
        }
    },
    methods: {
        // 点击衣物注意事项回调
        openClothingNote(){
            this.$alert(this.orderid.content2, '衣物注意事项', {
                confirmButtonText: '确定',
                callback: action => {
                    // this.$message({
                    //     type: 'info',
                    //     message: `action: ${ action }`
                    // });
                }
            });
        },
        // 点击备注回调
        openNote(){
            this.$alert(this.orderid.content, '备注', {
                confirmButtonText: '确定',
                callback: action => {
                    // this.$message({
                    //     type: 'info',
                    //     message: `action: ${ action }`
                    // });
                }
            });
        },
        handleMouseEnter() {
            this.isMouseOverImage = true;
        },
        handleMouseLeave() {
            this.isMouseOverImage = false;
        },
        handleWheel(event) {
            event.preventDefault();
            const delta = Math.sign(-event.deltaY);
            this.scale += delta * 0.1;
            this.scale = Math.max(0.1, Math.min(1, this.scale));
            this.$refs.imageWrapper.style.transform = `scale(${this.scale})`;
        },
        changPhoto(){
            this.showBigPhoto = false;
        },
        handleKeyup(e) {
            if (e.repeat) {
                return;
            }
            if (e.keyCode === 75) {
                console.log('k键被按下');
                this.captureImage();
            }else if(e.keyCode == 27){
                this.showBigPhoto = false;
            }
        },
        // 点击已拍照片放大回调
        toBig(index){
            this.showBigPhoto = true;
            this.photoUrl = this.capturedPhotos[index].url;
        },
        // 获取入厂时间
        getEntryTime(){
            const now = new Date();
            const year = now.getFullYear();
            const month = now.getMonth() + 1;
            const day = now.getDate();
            const time = now.toLocaleTimeString();
            this.entryTime = `${year}-${month}-${day} ${time}`;
        },
        // 生成条码
        generateBarcode(){
            const barcodeData = this.orderid.id; // 条码内容(衣物条形码也就是商品id)
            const canvas = this.$refs.barcodeCanvas;
            if(canvas == undefined){
                return;
            }else{
                JsBarcode(canvas, barcodeData,{
                    format: 'CODE128',
                    lineColor: '#000000',
                    width: 2,
                    height: 50,
                    displayValue: false,
                    margin: 0,
                    fontSize: 20
                });
                const dataUrl = canvas.toDataURL('image/png');
                this.imgUrl = dataUrl;
            }     
        },
        // 配置oss
        configureOSSClient() {
            try {
                this.ossClient = new OSS({
                    region: 'oss-cn-beijing', 
                    accessKeyId: 'LTAI5tQa4tD4qCbSVTzghqYT',
                    accessKeySecret: 'D9paNucPU5qWZDX79ZcLINd9DlgmMC',
                    bucket: 'jiekexihu'
                });
                } catch (error) {
                    console.error('配置 OSS 客户端时出错:', error);
            }
        }, 
        uploadPhotos(){
            let url = this.ossUrls
            this.$http.post(`/rfidlist/addImage?uid=${JSON.parse(localStorage.getItem('userinfo')).id}&goodsId=${this.orderid.id}&photo=${url}`,null,(res) => {
                    if(res.code === 1) {
                        // console.log(res,'上传图片成功');
                        this.$message({
                            type:'success',
                            message:'上传图片成功,请打印水洗唛'
                        })
                        this.capturedPhotos = []
                    }
                }
            );
            this.ossUrls = []
            this.Chaibaohotos = []
            this.showPrint = true
        },
        // 开启摄像头回调
        startCamera() {
            const video = this.$refs.video;
            if (navigator.mediaDevices.getUserMedia) {
                navigator.mediaDevices.getUserMedia({ 
                    video:{
                    width:{ideal:3264},
                    height:{ideal:2448},
                    frameRate: { ideal: 30, max: 30 }
                }
                // video:true
            })
                    .then(stream => {
                        if(!video){
                            return
                        }else{
                            video.srcObject = stream;
                        }
                    })
                    .catch(error => {
                        console.error('摄像头访问错误:', error);
                    });
            } else {
                alert('您的浏览器不支持摄像头访问');
            }
        },
        // 拍照回调 
        async captureImage() {
            const canvas = this.$refs.canvas;
            const video = this.$refs.video;
            if(!canvas){
                return
            }else{
                canvas.getContext('2d').drawImage(video, 0, 0, 3264, 2448);
            }
            canvas.toBlob((blob) => {
                // 创建一个 URL 来表示 Blob 对象
                const url = URL.createObjectURL(blob);

                // 将照片对象添加到 photos 数组中
                this.Chaibaohotos.unshift({ url, blob });
                console.log(this.Chaibaohotos);
                this.capturedPhotos.unshift({ url });
                // console.log(`Captured Image URL: ${url}`,this.capturedPhotos);
                // this.capturedPhotos = this.capturedPhotos.reverse();
                this.uploadToOSS(blob);
            }, 'image/png');
        },

        // 删除照片回调
        deletePhoto(index) {
            this.capturedPhotos.splice(index, 1);
            this.ossUrls.splice(index,1);
            this.Chaibaohotos.splice(index,1);
        },
        // 上传到oss回调
        async uploadToOSS(blob){
            if (this.capturedPhotos.length > 0) {
                try {
                    const fileName = `${Date.now()}`;
                    const file = new File([blob], 'image.png', { type: blob.type });
                    this.ossFile = file;
                    const result = await this.ossClient.put(fileName, this.ossFile,{
                        headers:{
                            'Content-Type': 'image/png',
                            'Content-Disposition': 'inline'
                        }
                    });
                    console.log('上传成功,OSS 图片 URL:', result.url);
                    // this.photoUrl = result.url;
                    // console.log(this.photoUrl);
                    // 把返回的oss地址存到一个数组中
                    this.ossUrls.push(result.url);
                }catch(error){
                    console.log('上传失败',error);
                }
            }else{
                console.log('未拍摄商品照片');
            }
        },     
        // 打印水洗唛回调
        // goPrint(){
        //     if(this.form.cloth == ''){
        //         this.$message({
        //             type: 'error',
        //             message: '请选择衣物'
        //         });
        //     }else if(this.form.resource == '' && this.colorContent == ''){
        //         this.$message({
        //             type: 'error',
        //             message: '请选择颜色'
        //         });
        //     }else if(this.form.type.length == 0 && this.flawContent == ''){
        //         this.$message({
        //             type: 'error',
        //             message: '请选择瑕疵'
        //         });
        //     }else if(this.form.resource3 == '' && this.otherContent == ''){
        //         this.$message({
        //             type:'error',
        //             message:'请选择附件'
        //         })
        //     }else{
        //         this.$nextTick(() => {
        //             let printableArea = this.$refs.printArea;
        //             let printWindow = window.open('', '_blank');
        //             printWindow.document.open();
        //             printWindow.document.write('<html><body>');
        //             printWindow.document.write(printableArea.innerHTML);
        //             printWindow.document.write('</body></html>');
        //             printWindow.document.close();
        //             printWindow.focus();
        //             printWindow.print();
        //         });
        //     };
        //     this.$emit('goPrint',1);
        // },
        goPrint(){
            this.$nextTick(() => {
                let printableArea = this.$refs.printArea;
                let printWindow = window.open('', '_blank');
                printWindow.document.open();
                printWindow.document.write('<html><body>');
                printWindow.document.write(printableArea.innerHTML);
                printWindow.document.write('</body></html>');
                printWindow.document.close();
                printWindow.focus();
                printWindow.print();
            });
            this.$emit('goPrint',1);
        },
        changePull() {
            console.log(this.selectedItem);
        },
        updateCategoryStatu() {
            this.updateCategory = true;

            let stat = this
            this.$http.post('receiving/getstatus', {}, function (data) {
                stat.pullDown = data.data
                console.log(stat.pullDown, '答案是');
            })
        },
        backStatusStatus() {

            this.backStatus = true;

            let stat = this
            this.$http.post('receiving/getstatus', {}, function (data) {
                stat.pullDown = data.data
                console.log(stat.pullDown, '答案是');
            })
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);

        },
        handlePictureCardPreview(file) {
            console.log(file)
            this.selectImg = file
            this.dialogImageUrl = this.httpimg + this.orderid.images_put[file];
            this.dialogVisible = true;
        },
        delereceiving(index) {
            this.orderid.images_put.splice(index, 1)
            let data = {
                id: this.orderid.id,
                images: this.orderid.images_put.join(',')
            }
            this.$http.post('/rfidlist/editimage', data, (res) => {
                console.log(res)
                if (res.code == 1) {
                    this.$message({
                        type: 'error',
                        message: '删除成功'
                    });

                } else {
                    this.$message({
                        type: 'error',
                        message: res.msg
                    });
                }
            })
        },
        delerejection(index) {
            this.orderid.images_qua.splice(index, 1)

            let data = {
                id: this.orderid.id,
                images: this.orderid.images_qua.join(',')
            }
            this.$http.post('/rfidlist/editpuaimage', data, (res) => {
                console.log(res)
                if (res.code == 1) {
                    this.$message({
                        type: 'error',
                        message: '删除成功'
                    });

                } else {
                    this.$message({
                        type: 'error',
                        message: res.msg
                    });
                }
            })
        },
        handlePictureCardPreviews(file) {
            console.log(file)
            this.selectImgs = file
            this.dialogImageUrls = this.httpimg + this.orderid.images_qua[file];
            this.dialogVisibles = true;
        },
        delereceivings(index) {
            this.orderid.images_qua.splice(index, 1)
        },
        prev() {
            console.log('2222')
            if (this.selectImg <= 0) {
                this.$message({
                    type: 'warning',
                    message: '当前已是第一张'
                });
            } else {
                this.selectImg -= 1
                this.dialogImageUrl = this.httpimg + this.orderid.images_put[this.selectImg];
            }
        },
        next() {
            console.log('1111111')
            if (this.selectImg >= this.orderid.images_put.length - 1) {
                this.$message({
                    type: 'warning',
                    message: '当前已是最后一张'
                });
            } else {
                this.selectImg += 1
                this.dialogImageUrl = this.httpimg + this.orderid.images_put[this.selectImg];
            }
        },
        prevs() {
            console.log('2222')
            if (this.selectImgs <= 0) {
                this.$message({
                    type: 'warning',
                    message: '当前已是第一张'
                });
            } else {
                this.selectImgs -= 1
                this.dialogImageUrl = this.httpimg + this.orderid.images_qua[this.selectImgs];
            }
        },
        nexts() {
            console.log('1111111')
            if (this.selectImgs >= this.orderid.images_qua.length - 1) {
                this.$message({
                    type: 'warning',
                    message: '当前已是最后一张'
                });
            } else {
                this.selectImgs += 1
                this.dialogImageUrls = this.httpimg + this.orderid.images_qua[this.selectImgs];
            }
        },
        handleSuccess(fileList) {
            console.log(fileList)
        },
        save() {
            if (this.textarea == '') {
                this.$message({
                    type: 'error',
                    message: '请输入拒收原因'
                })
                return;
            }
            if (this.selectedItem == '') {
                this.$message({
                    type: 'error',
                    message: '请选择拒收类型'
                })
                return;
            }
            var data = {
                content: this.textarea,
                ogid: this.orderid.id,
                id: localStorage.getItem('id'),
                status: this.selectedItem
            }
            this.$emit('sendRfid',this.orderid.rfid);
            console.log(data,'拒收参数');
            this.$http.post('/receiving/rejection', data, (res) => {
                console.log(res)
                if (res.code == 1) {
                    this.$message({
                        type: 'success',
                        message: '拒收成功'
                    })
                    this.textarea = ''
                        this.selectedItem= ''
                    this.updateCategory = false
                    if (this.authid == '1') {
                        
                        this.$emit('refresh', 0)
                    }
                    if (this.authid == '2') {
                        this.$emit('remove', this.giveStatus)
                    }

                } else {
                    this.$message({
                        type: 'error',
                        message: res.msg
                    });
                }
            })
            this.backStatus = false
        },
        back() {
            if (this.textareas == '') {
                this.$message({
                    type: 'error',
                    message: '请输入退回原因'
                })
                return;
            }
            if (this.selectedItemText == '') {
                this.$message({
                    type: 'error',
                    message: '请选择退回类型'
                })
                return;
            }
            var data = {
                content: this.textareas,
                ogid: this.orderid.id,
                id: localStorage.getItem('id'),
                status: this.selectedItemText
            }
            this.$http.post('/quality/editreturn', data, (res) => {
                console.log(res)
                if (res.code == 1) {
                    this.$message({
                        type: 'success',
                        message: '退回成功'
                    })
                    $.getJSON('http://127.0.0.1:19685/api/card/beginStockOut?uid=' + localStorage.getItem('id'), function (data) {
                        // eval(data);
                        console.log(data)
                    });
                    this.backStatus = false
                    this.$emit('refresh', 0)
                    this.orderid = {}
                    this.backAdd = true;
                    this.$emit('backAdd', this.backAdd)
                } else {
                    this.$message({
                        type: 'error',
                        message: res.msg
                    });
                }
            })
            this.backStatus = false
        },
        Unbind(e) {
            let self = this;
            if (e == '解绑成功') {
                var data = {
                    rfid: this.orderid.rfid,
                    ogid: this.orderid.id,
                    id: localStorage.getItem('id')
                }
                this.$http.post('/receiving/unbindput', data, (res) => {
                    console.log(res)
                    if (res.code == 1) {
                        self.showStatus = true
                        self.showText = e
                        setTimeout(function () {
                            console.log(11111)
                            self.showStatus = false
                        }, 3000)
                        this.$emit('refresh', 0)
                    } else {
                        this.$message({
                            type: 'error',
                            message: res.msg
                        });
                    }
                })
            }
            if (e == '质检合格') {
                if (this.orderid.images_qua.length == 0) {
                    this.$message({
                        type: 'error',
                        message: '请先上传质检图片'
                    })
                    return;
                }
                var data = {
                    image: this.orderid.images_qua,
                    ogid: this.orderid.id,
                    id: localStorage.getItem('id')
                }
                this.$http.post('/quality/editqualified', data, (res) => {
                    console.log(res)
                    if (res.code == 1) {
                        self.showStatus = true
                        self.showText = e
                        setTimeout(function () {
                            console.log(11111)
                            self.showStatus = false
                        }, 3000)
                        this.$emit('refresh', 0)
                    } else {
                        this.$message({
                            type: 'error',
                            message: res.msg
                        });
                    }
                })
            }

        },
        fileUpload(e) {
            console.log(e)
            let formData = new FormData()
            formData.append('file', e.target.files[0])
            axios.post('https://xcx.jiekee.com/api/common/upload', formData).then((res) => {
                console.log(res, '1111')
                // this.fileList.push(res.data.fullurl)
                this.orderid.images_put.push(res.data.data.url)
            })
            this.$emit('filelist', this.orderid.images_put);
            this.$refs.recive.value = ''
        },
        fileUploads(e) {
            console.log(e)
            let formData = new FormData()
            formData.append('file', e.target.files[0])
            axios.post('https://xcx.jiekee.com/api/common/upload', formData).then((res) => {
                console.log(res, '1111')
                // this.fileList.push(res.data.fullurl)
                this.orderid.images_qua.push(res.data.data.url)

            })
           
            this.$emit('filelistqua', this.orderid.images_qua);
            this.$refs.refuse.value = ''
        },
        bindJie() {
            var data = {
                ogid: this.orderid.id,
                id: localStorage.getItem('id')
            }
            this.$http.post('/quality/unbindpua', data, (res) => {
                console.log(res)
                if (res.code == 1) {
                    this.$message({
                        type: 'success',
                        message: '解绑成功'
                    });
                    this.$emit('twoOrder', this.orderid)

                    $.getJSON('http://127.0.0.1:19685/api/card/beginStockOut?uid=' + localStorage.getItem('id'), function (data) {
                        // eval(data);
                        console.log(data)
                    });
                    console.log(this.inputmax, '数据编码');
                    // let datas = {
                    //     recvRfid: localStorage.getItem('dataCoding'),
                    //     uid: localStorage.getItem('id')
                    // }
                    // this.$http.get('rfidlist/checkRfid', datas, (res) => {
                    // })
                } else {
                    this.$message({
                        type: 'error',
                        message: res.msg
                    });
                }
            })
        }
    }
}
</script>

<style>
/* @media print{
    #printableArea{
        display: none;
    }
} */
.flexPullDown {
    height: 40px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.ioo {
    width: 10%;
    display: flex;
}

.pullDown {
    height: 30px;
    width: 90%;
    margin-left: 20px;
    border: 1px solid #ccc;
}

.order_basic {
    margin-bottom: 0.25rem;
}
.orderInfo {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
}

.el-icon-arrow-down {
    font-size: 12px;
}

.demonstration {
    display: block;
    color: #8492a6;
    font-size: 14px;
    margin-bottom: 20px;
}

.order_basic_top {
    height: .375rem;
    background: #0099ff;
    color: #fff;
    width: 100%;
    line-height: .375rem;
    padding: 0 .25rem;
    font-size: 0.2rem;
    font-weight: 550;
}

.basic_content {
    display: flex;
    flex-flow: row wrap;
    border: 1px solid #ddd;
    border-bottom: none;
    font-size: 0.1875rem;
    color: #191919;
}

.basic_content li {
    width: 25%;
    height: .5rem;
    line-height: 0.5rem;
    padding: 0 .125rem;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
}

.orderInfo_status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 0.925rem;
    background: #d6ffcf;
    padding: 0 .25rem;
    color: #22d700;
    margin: 0.15rem 0 0;
}

.orderInfo_status i {
    font-size: .375rem;
}


.introduce_content ul {
    font-size: .175rem;
    line-height: .375rem;
}

.upload {
    flex: 1;
    display: flex;
    flex-flow: row wrap;
    margin-top: .375rem;
    max-height: 4rem;
    overflow-y: auto;
}

.images {
    width: 24%;
    height: 3.0875rem;
    margin-bottom: 0.25rem;
    margin-right: 1%;
    position: relative;
}

.images img {
    width: 100%;
    height: 100%;
}

.images i {
    position: absolute;
    top: 0.025rem;
    right: 0.025rem;
    font-size: 0.25rem;
}

.upload_img {
    width: 24%;
    height: 3.0875rem;
    margin-right: 1%;
    margin-bottom: 0.25rem;
    border: .025rem dashed #ddd;
    border-radius: .125rem;
    font-size: 1.2rem;
    color: #ddd;
    text-align: center;
    line-height: 3.0875rem;
    position: relative;
}

.upload_img input {
    width: 100%;
    height: 3.0875rem;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: 0;
    z-index: 20;
}

.introduce_btn {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.introduce_footer {
    /* padding: 0.23rem .15rem; */
    font-size: 0.2rem;
    color: rgba(0, 0, 0, 0.6);
    /* position: absolute;
    bottom: .375rem;
    width: 100%; */
}

.order_message {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.25rem;
}

.order_message p:nth-of-type(1) {
    width: 40%;
}

.order_message p:nth-of-type(2) {
    width: 60%;
}

.beizhu {
    display: flex;
    align-items: center;
    margin-bottom: 0.25rem;
}

.beizhu .el-input {
    width: 6.25rem;
}

.beizhu label {
    width: 1rem;
}

.save {
    width: 4rem;
    margin: 1rem auto 0;
}

.beizhu .el-textarea__inner {
    width: 6.25rem;
}

.centers {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 0.3rem;
    color: #333;
}

.centers i {
    font-size: 1.2rem;
    font-weight: 600;
    color: #0099ff;
    margin-bottom: .375rem;
}

.prev {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.25rem;
}
.photo-grid {
  margin-left: 20px;
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 1rem;
}
.photo{
    display: flex;
}

.photo-item {
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
  /* border: 1px solid black; */
}

.photo-item img {
    max-width: 100%;
    height: auto;
}

.delete-btn {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 2px;
  right: 2px;
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  cursor: pointer;
}
.canvas{
    display: none;
}
.videoNow{
    box-sizing: border-box;
    /* width: 200px;
    height: 200px; */
    border: 2px solid red;
    box-sizing: border-box;
}
.photoContent{
    display: flex;
    justify-content: space-between;
}

.toPrintContent{
    display: flex;
    justify-content: space-between;
}
.elButton{
    width: 2rem;
    margin: 1rem auto 0;
}
.photoInfo-btn{
    position: absolute;
    left: 640px;
    width: 20px;
    height: 20px;
    background: #fff;
    line-height: 20px;
    text-align: center;
}
</style>